<template>
  <div class="my-6 flex gap-3 ml-8">
    <router-link
      class="text-yellow-500 font-bold underline"
      to="/firmware_management/1"
      >Firmware Management</router-link
    >
    |
    <!-- <router-link
      class="text-yellow-500 font-bold underline"
      to="/firmware_management/byUser"
      >OTA by User</router-link
    > -->
    <a
      class="text-yellow-500 font-bold underline"
      :href="$router.resolve({ name: 'FirmwareManagementByUser' }).href"
      >OTA by User</a
    >
  </div>

  <router-view></router-view>
</template>
